import React, { useState, useEffect } from 'react';

import axiosInstance from '../../axios';
import { Link } from 'react-router-dom';

import { Eye } from '@styled-icons/heroicons-solid/Eye';
import { TrashBin } from '@styled-icons/ionicons-outline/TrashBin';
import { IndentMore } from '@styled-icons/foundation/IndentMore';
import { Edit } from '@styled-icons/boxicons-regular/Edit';
import { UserDetail } from '@styled-icons/boxicons-solid/UserDetail';
import { StatusOffline } from '@styled-icons/heroicons-outline/StatusOffline';
import { StatusOnline } from '@styled-icons/heroicons-outline/StatusOnline';
import { EditOff } from '@styled-icons/material/EditOff';
import { Data } from 'styled-icons/boxicons-regular';

const MTable = ({ mandantes, setMandantes, setChange }) => {
  const [confO, setConfO] = useState(false);
  const [idO, setIdO] = useState(null);
  const [ob, setOb] = useState({});
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState({});

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const editR = (obj) => {
    setEdit(true);
    setData(obj);
    setOb(obj);
  };
  const endEdit = () => {
    if (JSON.stringify(data) !== JSON.stringify(ob)) {
      axiosInstance.patch(`mandantes/${ob.id}/`, data).then((res) => {
        setChange(Date().toLocaleString());
      });
    }

    setEdit(false);
    setData({});
    setOb({});
  };

  const close = () => {
    setIdO(null);
    setConfO(false);
  };
  const rem = (obj) => {
    setOb(obj);
    setIdO(obj.id);
    setConfO(true);
  };

  const del = () => {
    axiosInstance.delete(`mandantes/${idO}/`).then((res) => {
      setMandantes(mandantes.filter((x) => x.id !== idO));
      setChange(idO);
    });

    setConfO(false);
    setIdO(null);
    setOb({});
  };

  useEffect(() => {});

  return (
    <table className="w-full table-auto rounded-lg shadow-2xl overflow-hidden">
      {confO && (
        <div className="flex flex-wrap ml-96 flex-col h-1/5 bg-gray-300 shadow-2xl rounded-xl pt-3 px-2 w-3/12 fixed pin z-50 overflow-auto bg-smoke-light flex">
          <div className="flex flex-wrap w-full h-2/3 justify-center">
            <p className="font-bold text-xl h-1/3">Eliminar este Mandante:</p>
            <div className="flex justify-center w-full h-2/3 text-4xl font-bold">
              <p className="my-auto">{ob.nombre}</p>
            </div>
          </div>
          <div className="flex justify-center w-full h-1/3 items-end pb-2">
            <button
              onClick={() => close()}
              className=" focus:outline-none mx-4 py-2 px-4 bg-white rounded-md font-bold shadow-md tracking-wide uppercase border border-black cursor-pointer hover:bg-black hover:text-white text-black ease-linear transition-all duration-150"
            >
              Cancelar
            </button>

            <button
              type="submit"
              onClick={() => del()}
              className=" focus:outline-none mx-4 py-2 px-5 bg-white rounded-md font-bold shadow-md tracking-wide uppercase border border-red-500 cursor-pointer hover:bg-red-600 hover:text-white text-red-600 ease-linear transition-all duration-150"
            >
              Eliminar
            </button>
          </div>
        </div>
      )}
      <thead>
        <tr>
          <th
            scope="col"
            className="px-5 py-3 bg-gray-200  border-b border-gray-200 text-gray-800  text-left text-lg uppercase font-normal"
          >
            Nombre
          </th>
          <th
            scope="col"
            className="px-5 py-3 bg-gray-200  border-b border-gray-200 text-gray-800  text-left text-lg uppercase font-normal"
          >
            Codigo
          </th>
          <th
            scope="col"
            className="px-5 py-3 bg-gray-200 border-b border-gray-200 text-gray-800  text-left text-lg uppercase font-normal"
          >
            Editar
          </th>
          <th
            scope="col"
            className="px-5 py-3 bg-gray-200 border-b border-gray-200 text-gray-800  text-left text-lg uppercase font-normal"
          >
            Eliminar
          </th>
        </tr>
      </thead>
      <tbody>
        {mandantes &&
          mandantes.map((obj) => (
            <tr key={obj.id} className="">
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-lg">
                <div className="flex items-center">
                  <div className="ml-3">
                    {edit && obj === ob ? (
                      <input
                        name="nombre"
                        value={data['nombre']}
                        onChange={handleChange}
                        className="w-full border-gray-800 rounded-lg border-3 p-2 ml-1 border px-1 my-1"
                      ></input>
                    ) : (
                      <p className="text-lg text-gray-900 whitespace-no-wrap">{obj.nombre}</p>
                    )}
                  </div>
                </div>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-lg">
                {edit && obj === ob ? (
                  <input
                    type="text"
                    value={data['codigo']}
                    className="w-full border-gray-800 border rounded-lg border-3 p-2"
                    name="week"
                    onChange={handleChange}
                  ></input>
                ) : (
                  <p className="text-lg text-gray-900 whitespace-no-wrap">{obj.codigo}</p>
                )}
              </td>

              <td className="px-5 py-5 border-b border-gray-200 bg-white text-lg">
                {edit && obj === ob ? (
                  <EditOff
                    onClick={() => endEdit()}
                    size="32"
                    className="my-auto text-blue-800 transform hover:scale-125"
                  />
                ) : (
                  <Edit
                    onClick={() => editR(obj)}
                    size="32"
                    className="my-auto text-blue-600 transform hover:scale-125"
                  />
                )}
              </td>

              <td className="px-5 py-5 border-b border-gray-200 bg-white text-lg">
                <button
                  onClick={() => rem(obj)}
                  className="text-red-500 hover:text-red-600 transform hover:scale-125"
                >
                  <TrashBin size="35" className="" />
                </button>
              </td>
            </tr>
          ))}

        <tr className="h-20"></tr>
      </tbody>
    </table>
  );
};

export default MTable;
