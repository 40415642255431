import {useState,useEffect} from 'react'
import axiosInstance from '../../axios.js'

import TagsTable from '../../components/tags/TagsTable.jsx'

import { XCircle } from '@styled-icons/boxicons-solid/XCircle';

const TagsContacto = () =>{
  const [objs,setObjs] = useState([])
  const [data,setData] = useState({nombre:'',dominio:''})
  const [create,setCreate] = useState(false)

  const handleChange = (e) =>{
    setData({...data,[e.target.name]:e.target.value})
  }
  const getObjs = async () =>{
    await axiosInstance.get(`tags_contacto`).then(res=>{
      setObjs(res.data) 
    })
  }

  const addObj = async () =>{
    
    data.nombre !== '' && await axiosInstance.post(`tags_contacto/`,data).then(res=>{
      setData({nombre:'',color:''})
      getObjs()
      setCreate(false)
    })
  }

  useEffect(()=>{
    getObjs()

  },[])
  return (
    <div className="w-full h-full bg-gray-100 flex flex-wrap justify-between content-start gap-y-5 overflow-y-scroll ">
      {create === true && 
        <div className="absolute w-full h-full flex justify-center items-center">

          <div className="w-2/6 h-2/4 bg-white border rounded-md z-40 shadow-lg flex flex-wrap content-start gap-y-2 justify-center">
            <div className="w-full h-8 border-b flex justify-end items-center px-2"><XCircle onClick={()=>{setData(null);setCreate(false)}} size="24" className="text-red-500 transform hover:scale-105 cursor-pointer"/></div>

            <div className="w-full flex p-2 items-center">
              <p className="w-1/2 text-center">Nombre</p>
              <input name="nombre" onChange={handleChange} value={data['nombre']} className="px-2 py-1 w-1/2 border"></input>
          </div>
          <div className="w-full flex p-2 items-center">
              <p className="w-1/2 text-center">Color</p>
              <input name="color" type="color" onChange={handleChange} value={data['color']} className="px-2 py-1 w-1/2 border"></input>
          </div>
          <div className="w-full flex p-2 items-center">
              <p className="w-1/2 text-center">Descripcion</p>
              <textarea name="description" type="text" onChange={handleChange} value={data['description']} className="px-2 py-1 w-1/2 border"></textarea>
          </div>
            
            <div onClick={()=>addObj()} className="rounded-md w-auto px-2 py-1 bg-green-500 text-gray-50 transform hover:scale-105 cursor-pointer">Crear</div>

          </div>
        </div>}

      <div className="w-full h-14 border-b bg-white flex justify-between items-center p-4">
        <p className="text-xl font-normal">Tags Contacto</p> <div onClick={()=>setCreate(!create)} className="rounded-md w-auto px-2 py-1 bg-blue-ligth text-gray-50 transform hover:scale-105 cursor-pointer">Crear</div>
      </div>

        <div className="px-4  w-full flex flex-wrap justify-start content-start pb-12">

            <TagsTable objs={objs} getObjs={getObjs}></TagsTable>
        </div> 

                
      </div>
  )
}

export default TagsContacto;

  
