import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';

import fileDownload from 'js-file-download'
import toast from 'react-hot-toast'

const RespuestaAction = ({ llamadas,download }) => {
  var user = JSON.parse(localStorage.getItem('user'));
  const [show, setShow] = useState(false);
  const [tipoS, setTipoS] = useState(null);
  const [operators, setOperators] = useState([]);
  const [agentes, setAgentes] = useState([]);
  const [agente, setAgente] = useState(null);
  const [status, setStatus] = useState(null);
  const [operator, setOperator] = useState(null);
  const [data, setData] = useState({
    user: user.id,
  });

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const createAction = () => {
    if (tipoS === 1) {
      axiosInstance
        .post(`mandantes/informe_falabella/`, {
          ['ids']: llamadas.map((ll) => {
            return ll.id;
          }),
          mandante: 'CMR',
        })
        .then((res) => {
          setShow(false);
        });
    } else if (tipoS === 2) {
      axiosInstance
        .post(`mandantes/informe_falabella/`, {
          ['ids']: llamadas.map((ll) => {
            return ll.id;
          }),
          mandante: 'BCO. FALABELLA',
        })
        .then((res) => {
          setShow(false);
        });
    } else if (tipoS === 3) {
      var prom = axiosInstance
        .post(`informes/reg200/`,{
          ids: llamadas.map((ll) => {
            return ll.id;
          })
        })
        .then((res) => {
          setShow(false)
          // console.log(res.headers.filename)
          fileDownload(res.data,res.headers.filename)
        });
       toast.promise(prom, {
        loading: 'Generando',
        success: 'Listo',
        error: 'Error',
      });
    } else if (tipoS === 4){
      download()
      setShow(false)
    }
  };

  useEffect(() => {}, [show]);

  return (
    <div className="absolute w-48 flex flex-wrap  z-30 bg-blue-normal transform -translate-x-40 h-auto">
      <div
        onClick={() => {
          setTipoS(1);
          setShow(!show);
        }}
        className="w-full h-12 border-b flex pl-1 hover:bg-blue-semi cursor-pointer"
      >
        <p className="text-white my-auto text-center">Informe CMR</p>
      </div>
      <div
        onClick={() => {
          setTipoS(2);
          setShow(!show);
        }}
        className="w-full h-12 border-b flex pl-1 hover:bg-blue-semi cursor-pointer"
      >
        <p className="text-white my-auto text-center">Informe BCO. Fallabella</p>
      </div>
      <div
        onClick={() => {
          setTipoS(3);
          setShow(!show);
        }}
        className="w-full h-12 border-b flex pl-1 hover:bg-blue-semi cursor-pointer"
      >
        <p className="text-white my-auto text-center">Informe Reg200</p>
      </div>
      <div
        onClick={() => {
          setTipoS(4);
          setShow(!show);
        }}
        className="w-full h-12 border-b flex pl-1 hover:bg-blue-semi cursor-pointer"
      >
        <p className="text-white my-auto text-center">Descargar</p>
      </div>
      {show && (
        <div className="rounded-lg flex p-2 flex-wrap content-start w-80 h-80 bg-blue-normal absolute transform -translate-x-80 overflow-y-scroll no-scrollbar">
          <div className="w-full h-3/6  flex justify-around py-1">
            <button
              onClick={() => setShow(false)}
              className="h-12 rounded-lg bg-red-600 px-3 py-1 text-gray-100"
            >
              Cerrar
            </button>
            <button
              onClick={() => createAction()}
              className="h-12 rounded-lg bg-green-600 px-3 py-1 text-gray-100"
            >
              Enviar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RespuestaAction;
